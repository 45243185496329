import { Box, Typography } from '@mui/material';
import React from 'react';

const TableEmptyComponent = (props) : React.JSX.Element => 
{
    
    const emptyImg = props.emptyImg;
    //const openDrawer = props.openDrawer;
    const infoText = props.infoText;
    const subInfoText = props.subInfoText;
    //const buttonName = props.buttonName;

    return (
        <Box className="table-empty-container">
            <Box component="img" src={emptyImg} />
            <Box className="empty-info-container">
                <Typography className="empty-info-text">{infoText}</Typography>
                <Typography className="empty-info-sub-text">{subInfoText}</Typography>
            </Box>
            {/* {buttonName ? <Button type="button" onClick={openDrawer} className="create-button">{buttonName}</Button> : null} */}
        </Box>
    );
};

export default TableEmptyComponent;