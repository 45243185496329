import React, { useEffect, useState } from 'react';
import eventBus from '../scripts/event-bus';
import APP_CONSTANTS from '../scripts/constants';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';

const CustomDialog = (): React.JSX.Element => 
{

    const [componentHeight, setComponentHeight] = useState('');
    const [componentWidth, setComponentWidth] = useState('');

    const [component, setComponent] = useState(<div></div>);
    const [heading, setHeading] = useState<string | React.JSX.Element>();
    const [open, setOpen] = useState<boolean>(false);

    const [iconHeading, setIconHeading] = useState<IconName | ''>('');

    const headerIconStyle: React.CSSProperties = {
        padding: '12px',
        borderRadius: 'var(--radius-lg, 10px)',
        border: '1px solid var(--Component-colors-Components-Icons-Featured-icons-Modern-featured-icon-modern-border, #EAECF0)',
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
        height: '48px',
        width: '48px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const handleDialogClose = (): void => 
    {
        setOpen(false);
    };

    const handleEscapeKeyClose = (event, reason: string): void => 
    {
        if (reason === 'escapeKeyDown') 
        {
            handleDialogClose();
        }
    };

    useEffect(():()=>void => 
    {
        const updateEvent = (data): void => 
        {
            setHeading(data.heading);
            setComponent(data.component);
            setComponentHeight(data.componentHeight);
            setComponentWidth(data.componentWidth);
            setIconHeading(data.iconHeading);
        };

        const openEvent = (data): void => 
        {
            setOpen(data.open);
        };

        const closeEvent = (): void => 
        {
            handleDialogClose();
        };

        eventBus.on(APP_CONSTANTS.EVENTS.DIALOG.UPDATE_EVENT, updateEvent);
        eventBus.on(APP_CONSTANTS.EVENTS.DIALOG.OPEN_EVENT, openEvent);
        eventBus.on(APP_CONSTANTS.EVENTS.DIALOG.CLOSE_EVENT, closeEvent);
        eventBus.on(APP_CONSTANTS.EVENTS.DIALOG.CLOSE_EVENT, (data): void => 
        {
            setOpen(data.open);
        });

        return ():void =>
        {
            eventBus.remove(APP_CONSTANTS.EVENTS.DIALOG.UPDATE_EVENT, updateEvent);
            eventBus.remove(APP_CONSTANTS.EVENTS.DIALOG.OPEN_EVENT, openEvent);
            eventBus.remove(APP_CONSTANTS.EVENTS.DIALOG.CLOSE_EVENT, closeEvent);
        };

    }, []);

    return (
        <div id="customDialog">
            <Dialog slotProps={{
                backdrop: {
                    sx: {
                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    }
                }
            }} PaperProps={{
                sx: {
                    minWidth: componentHeight ? componentWidth : '416px',
                    minHeight: componentHeight ? componentHeight : '318px',
                    maxHeight: '100%',
                    maxWidth: '100%',
                    height: componentHeight ? componentHeight : '',
                    width: componentWidth ? componentWidth : '',
                    borderRadius: '12px',
                    boxShadow: '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
                }
            }} open={open} onClose={handleEscapeKeyClose} className="custom-dialog-container">
                <DialogTitle sx={{
                    padding: '24px 24px 16px 24px',
                    color: '#2A2E34',
                    fontSize: '16px',
                    fontWeight: 500,
                    fontStyle: 'normal',
                    lineHeight: '22px',
                    letterSpacing: '-0.1px',
                    display: 'flex',    
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                }}>
                    {heading}
                    {iconHeading && <div style={headerIconStyle}><FontAwesomeIcon icon={['fal', iconHeading]} /></div>}
                    
                    <FontAwesomeIcon icon={['fal', 'xmark']} onClick={handleDialogClose} cursor={'pointer'} />
                </DialogTitle>
                <DialogContent sx={{
                    padding: 'unset',
                }}>{component}</DialogContent>
            </Dialog>
        </div>
    );
};

export default CustomDialog;