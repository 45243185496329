import React, { useEffect, useState } from 'react';
import * as formik from 'formik';
import * as Yup from 'yup';
import { Box, IconButton, Typography } from '@mui/material';
import { Form, Spinner } from 'react-bootstrap';
import { acceptInviteWithWorkOS, onBoardWithWorkOS, updatePasswordWithWorkOS } from '../../scripts/apis/authentication';
import { useNavigate } from 'react-router-dom';
import { getUserOrgInfoByToken } from '../../scripts/apis/authentication';
import { checkOrgLinkAvailability } from '../../scripts/apis/organisation';
import { inviteStatus } from '../../scripts/apis/authentication';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserFlowPageProps } from './UserFlowPageInterface';

import eventHqLogo from '../../assets/favicon.png';
import { FormControlComponent, FormLabelComponent } from '../../common/FormComponents/ReusableFormComponents';
import { CustomButton } from '../../common/FormComponents/Buttons';
import { UserOrgStatus, UserStatus } from '../Settings/enum/users.enum';

import { userMe } from '../../scripts/apis/users';
import toast from 'react-hot-toast';
import APP_CONSTANTS from '../../scripts/constants';

const UserFlowPage = (props: UserFlowPageProps): React.JSX.Element => 
{
    const navigate = useNavigate();
    const token = props.token;
    const isResetPasswordComponent = props.component === 'forgot-password';
    const isRegisterComponent = props.component === 'register';
    // const isOnboardComponent = props.component === 'onboard';

    const defaultUserObj = {
        id: 0,
        email: '',
        firstName: '',
        lastName: '',
        status: 0,
        additionalInfo: {
            image: null,
        },
        orgId: 0,
        orgStatus: 0,
        role: 0,
        lastActive: '',
        created: '',
        modified: '',
        orgName: '',
        orgLink: '',
    };

    const [userObj, setUserObj] = useState(defaultUserObj);
    const [subDomainName, setSubDomainName] = useState('');
    const [errorMsgFromServer, setErrorMsgFromServer] = useState('');
    const [spinner, setSpinner] = useState(false);
    const [subDomainSpinner, setSubDomainSpinner] = useState(false);
    const [autoAcceptSpinner, setAutoAcceptSpinner] = useState(false);
    const [componentConditions, setComponentConditions] = useState<{
        isSubDomainAvailable: boolean,
        passwordHas8Characters: boolean,
        passwordHasSpecialCharacterAndCapitalAndUppercase: boolean,
        showPassword: boolean,
        showConfirmPassword: boolean,
        isTokenExpired: boolean,
        isErrorFromServer: boolean,
        isOnboardUserDetails?: boolean,
        // isOnboardOrgDetails?: boolean,
    }>({
        isSubDomainAvailable: false,
        passwordHas8Characters: false,
        passwordHasSpecialCharacterAndCapitalAndUppercase: false,
        showPassword: false,
        showConfirmPassword: false,
        isTokenExpired: false,
        isErrorFromServer: false,
        isOnboardUserDetails: isRegisterComponent,
        // isOnboardOrgDetails: false,
    });

    const handlePasswordEligibility = (data: string):void => 
    {
        const passwordSpecialCharCapitalNumberRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+\s]+$/;
        
        setComponentConditions((prevItems):{
            isSubDomainAvailable: boolean;
            passwordHas8Characters: boolean;
            passwordHasSpecialCharacterAndCapitalAndUppercase: boolean;
            showPassword: boolean;
            showConfirmPassword: boolean;
            isTokenExpired: boolean;
            isErrorFromServer: boolean;
        } => 
        {
            return {
                ...prevItems,
                passwordHas8Characters: data.length >= 8,
                passwordHasSpecialCharacterAndCapitalAndUppercase: passwordSpecialCharCapitalNumberRegex.test(data),
            }; 
        });
    };

    const registerUser = async (values: {
        firstName: string;
        lastName: string;
        email: string;
        password: string;
        confirmPassword: string;
    }):Promise<void> => 
    {
        const registerObj = {
            accessToken: token,
            firstName: values.firstName,
            lastName: values.lastName,
            password: values.password,
            confirmPassword: values.confirmPassword,
        };

        try 
        {
            const userRegistered = await onBoardWithWorkOS(registerObj);
            if (userRegistered) 
            {
                setSpinner(false);
                try 
                {
                    const user = await userMe();
                    if(user)
                    {
                        // navigate('/events');
                        window.location.href = `${APP_CONSTANTS.APP_DOMAIN}/events`;
                    }
                } 
                catch (error) 
                {
                    console.log(error);
                    navigate('/login');
                }
            }
        }
        catch (error) 
        {
            setSpinner(false);
            toast.error(error?.message || 'Something went wrong');
            setComponentConditions((prevItems):{
                isSubDomainAvailable: boolean;
                passwordHas8Characters: boolean;
                passwordHasSpecialCharacterAndCapitalAndUppercase: boolean;
                showPassword: boolean;
                showConfirmPassword: boolean;
                isTokenExpired: boolean;
                isErrorFromServer: boolean;
            } => 
            {
                return {
                    ...prevItems,
                    isErrorFromServer: true,
                }; 
            });
            setErrorMsgFromServer(error.message);
            console.log(error);
        }
    };

    const handleRegister = (values):void => 
    {
        registerUser(values);
    };

    const onBoardUser = async (values: {
        password: string;
        confirmPassword: string;
        firstName: string;
        lastName: string;
    }):Promise<void> => 
    {
        const onboardObj = {
            accessToken: token,
            firstName: values.firstName,
            lastName: values.lastName,
            password: values.password,
            confirmPassword: values.confirmPassword,
        };

        try 
        {
            const userOnboarded = await acceptInviteWithWorkOS(onboardObj);
            if (userOnboarded) 
            {
                setSpinner(false);
                try 
                {
                    const user = await userMe();
                    if(user)
                    {
                        // navigate('/events');
                        window.location.href = `${APP_CONSTANTS.APP_DOMAIN}/events`;
                    }
                } 
                catch (error) 
                {
                    console.log(error);
                    toast.error(error?.message || 'Something went wrong');
                }
            }
        }
        catch (error) 
        {
            setSpinner(false);
            setComponentConditions((prevItems):{
                isSubDomainAvailable: boolean;
                passwordHas8Characters: boolean;
                passwordHasSpecialCharacterAndCapitalAndUppercase: boolean;
                showPassword: boolean;
                showConfirmPassword: boolean;
                isTokenExpired: boolean;
                isErrorFromServer: boolean;
            } => 
            {
                return {
                    ...prevItems,
                    isErrorFromServer: true,
                }; 
            });
            setErrorMsgFromServer(error.message);
            console.log(error);
        }
    };

    const handleOnboard = (values):void => 
    {
        onBoardUser(values);
    };

    const resetPassword = async (values: {
        password: string;
        confirmPassword: string;
    }):Promise<void> => 
    {
        const passwordObj = {
            accessToken: token,
            password: values.password,
            confirmPassword: values.confirmPassword,
        };
        try 
        {
            const passwordReset = await updatePasswordWithWorkOS(passwordObj);
            if (passwordReset) 
            {
                setSpinner(false);
                navigate('/login');
            }
        }
        catch (error) 
        {
            setSpinner(false);
            toast.error(error?.message || 'Something went wrong');
            setErrorMsgFromServer(error?.message);
            setComponentConditions((prevItems):{
                isSubDomainAvailable: boolean;
                passwordHas8Characters: boolean;
                passwordHasSpecialCharacterAndCapitalAndUppercase: boolean;
                showPassword: boolean;
                showConfirmPassword: boolean;
                isTokenExpired: boolean;
                isErrorFromServer: boolean;
            } => 
            {
                return {
                    ...prevItems,
                    isErrorFromServer: true,
                }; 
            });
            // if (error.statusCode === 400 && error.message === 'Password already used') 
            // {
            //     setErrorMsgFromServer('This password has been used before. Please choose a new and unique password.');
            // }
            console.log(error);
        }
    };

    const handleTogglePassword = ():void => 
    {
        setComponentConditions((prevItems):{
            isSubDomainAvailable: boolean;
            passwordHas8Characters: boolean;
            passwordHasSpecialCharacterAndCapitalAndUppercase: boolean;
            showPassword: boolean;
            showConfirmPassword: boolean;
            isTokenExpired: boolean;
            isErrorFromServer: boolean;
        } => 
        {
            return {
                ...prevItems,
                showPassword: !componentConditions.showPassword,
            }; 
        });
    };

    const handleToggleConfirmPassword = ():void => 
    {
        setComponentConditions((prevItems):{
            isSubDomainAvailable: boolean;
            passwordHas8Characters: boolean;
            passwordHasSpecialCharacterAndCapitalAndUppercase: boolean;
            showPassword: boolean;
            showConfirmPassword: boolean;
            isTokenExpired: boolean;
            isErrorFromServer: boolean;
        } => 
        {
            return {
                ...prevItems,
                showConfirmPassword: !componentConditions.showConfirmPassword,
            }; 
        });
    };

    const handleResetPassword = (values: {
        password: string;
        confirmPassword: string;
    }):void => 
    {
        resetPassword(values);
    };

    const validationSchema = Yup.object().shape(
        isResetPasswordComponent ? {
            email: Yup.string().email(),
            password: Yup.string()
                .required('Password is required')
                .min(8)
                .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+\s]+$/, 'Password should match the below criteria'),
            confirmPassword: Yup.string()
                .required('Confirm your password')
                .oneOf(
                    [Yup.ref('password'), null],
                    'Passwords must match'
                ),
        } : isRegisterComponent ? {
            firstName: Yup.string()
                .matches(/^[^!@#$%^&*()_+\d]+$/, 'Enter alphabets only')
                .required('First Name is required'),
            lastName: Yup.string()
                .matches(/^[^!@#$%^&*()_+\d]+$/, 'Enter alphabets only')
                .required('Last Name is required'),
            email: Yup.string().email(),
            password: Yup.string()
                .required('Password is required')
                .min(8)
                .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+\s]+$/, 'Password should match the below criteria'),
            confirmPassword: Yup.string()
                .required('Confirm your password')
                .oneOf(
                    [Yup.ref('password'), null],
                    'Passwords must match'
                ),
        }
        //  : isOnboardComponent ? {
        //     email: Yup.string().email(),
        //     firstName: Yup.string()
        //         .matches(/^[^!@#$%^&*()_+\d]+$/, 'Enter alphabets only')
        //         .required('First Name is required'),
        //     lastName: Yup.string()
        //         .matches(/^[^!@#$%^&*()_+\d]+$/, 'Enter alphabets only')
        //         .required('Last Name is required'),
        //     password: Yup.string()
        //         .required('Password is required')
        //         .min(8)
        //         .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+\s]+$/, 'Password should match the below criteria'),
        //     confirmPassword: Yup.string()
        //         .required('Confirm your password')
        //         .oneOf(
        //             [Yup.ref('password'), null],
        //             'Passwords must match'
        //         ),
        // }
         : {}
    );

    const { Formik } = formik;

    const fetchUserData = async ():Promise<void> => 
    {
        if (token) 
        {
            try 
            {
                const userData = await getUserOrgInfoByToken(token);
                if (userData) 
                {
                    setUserObj({
                        ...defaultUserObj,
                        ...userData,
                    });

                    if(userData?.status === UserStatus.ACTIVE && userData?.orgStatus === UserOrgStatus.ACTIVE)
                    {
                        setAutoAcceptSpinner(true);
                        setTimeout(() => 
                        {
                            setAutoAcceptSpinner(false);
                            return navigate('/login');
                        }, 2000);
                    }

                    if (!isResetPasswordComponent) 
                    {
                        try 
                        {
                            await inviteStatus(token);
                            setSubDomainName(userData?.orgLink);
                            setComponentConditions((prevItems):{
                                isSubDomainAvailable: boolean;
                                passwordHas8Characters: boolean;
                                passwordHasSpecialCharacterAndCapitalAndUppercase: boolean;
                                showPassword: boolean;
                                showConfirmPassword: boolean;
                                isTokenExpired: boolean;
                                isErrorFromServer: boolean;
                            } => 
                            {
                                return {
                                    ...prevItems,
                                    isSubDomainAvailable: false,
                                }; 
                            });
                        }
                        catch (error) 
                        {
                            console.log(error);
                        }

                    }
                }
            }
            catch (error) 
            {
                console.log(error);
                if (error.statusCode === 401 && error.message === 'Auth expired') 
                {
                    setComponentConditions((prevItems):{
                        isSubDomainAvailable: boolean;
                        passwordHas8Characters: boolean;
                        passwordHasSpecialCharacterAndCapitalAndUppercase: boolean;
                        showPassword: boolean;
                        showConfirmPassword: boolean;
                        isTokenExpired: boolean;
                        isErrorFromServer: boolean;
                    } => 
                    {
                        return {
                            ...prevItems,
                            isTokenExpired: true,
                        }; 
                    });
                }
            }
        }
    };

    useEffect(():void => 
    {
        // if(isOnboardComponent || isRegisterComponent)
        if(isRegisterComponent)
        {
            fetchUserData();
        }
    }, []);

    useEffect(():()=>void => 
    {
        const delayDebounceFn = setTimeout(async ():Promise<void> => 
        {
            if (subDomainName.length > 1 && subDomainName && !(userObj.orgLink === subDomainName)) 
            {
                setSubDomainSpinner(true);
                const linkAvailable = await checkOrgLinkAvailability(subDomainName);
                setSubDomainSpinner(false);
                setComponentConditions((prevItems): {
                    isSubDomainAvailable: boolean;
                    passwordHas8Characters: boolean;
                    passwordHasSpecialCharacterAndCapitalAndUppercase: boolean;
                    showPassword: boolean;
                    showConfirmPassword: boolean;
                    isTokenExpired: boolean;
                    isErrorFromServer: boolean;
                } => 
                {
                    return {
                        ...prevItems,
                        isSubDomainAvailable: linkAvailable === 'true',
                    }; 
                });
            }
            else 
            {
                setComponentConditions((prevItems):{
                    isSubDomainAvailable: boolean;
                    passwordHas8Characters: boolean;
                    passwordHasSpecialCharacterAndCapitalAndUppercase: boolean;
                    showPassword: boolean;
                    showConfirmPassword: boolean;
                    isTokenExpired: boolean;
                    isErrorFromServer: boolean;
                } => 
                {
                    return {
                        ...prevItems,
                        isSubDomainAvailable: false,
                    }; 
                });
            }
        }, 500);

        return ():void => 
        {
            return clearTimeout(delayDebounceFn);
        };
    }, [subDomainName]);

    const getTitleContent = () => {
        if (componentConditions.isOnboardUserDetails) return 'Enter your details';
        // if (componentConditions.isOnboardOrgDetails) return 'Enter your company details';
        if(isResetPasswordComponent) return 'Set new password'
        // if (isOnboardComponent) return 'Join your team';
    };
    
    const getSubTitleContent = () => {
        if(componentConditions.isOnboardUserDetails) return 'Please enter your details'
        // if (componentConditions.isOnboardOrgDetails) return 'Please enter your company details';
        // if (isOnboardComponent) return `Someone has invited you to use EventHQ with them in a workspace called ${userObj?.orgName}`;
        if(isResetPasswordComponent) return 'Your new password must be different to previously used passwords.'
        return '';
    };

    return (
        <Box id="authPage">
            <img src={eventHqLogo} alt="eventHqLogo" className="header-logo-img" />
            <Formik
                validationSchema={validationSchema}
                enableReinitialize={true}
                validateOnChange={false}
                initialTouched={
                    isResetPasswordComponent ? {
                        email: false,
                        password: false,
                        confirmPassword: false,
                    } : isRegisterComponent ? {
                        firstName: false,
                        lastName: false,
                        email: false,
                        password: false,
                        confirmPassword: false,
                    } 
                    // : isOnboardComponent ? {
                    //     firstName: false,
                    //     lastName: false,
                    //     email: false,
                    //     password: false,
                    //     confirmPassword: false,
                    // } 
                    : {}
                }
                initialValues={
                    isResetPasswordComponent ? {
                        email: userObj.email,
                        password: '',
                        confirmPassword: '',
                    } : isRegisterComponent ? {
                        firstName: '',
                        lastName: '',
                        email: userObj.email,
                        password: '',
                        confirmPassword: '',
                    } 
                    // : isOnboardComponent ? {
                    //     firstName: '',
                    //     lastName: '',
                    //     email: userObj.email,
                    //     password: '',
                    //     confirmPassword: '',
                    // } 
                    : {}
                }
                onSubmit={(values):void => 
                {
                    setSpinner(true);
                    if (isResetPasswordComponent) 
                    {
                        return handleResetPassword(values);
                    }
                    // else if (isRegisterComponent || isOnboardComponent) 
                    else if (isRegisterComponent) 
                    {
                        return handleRegister(values);
                    }
                    // else if (isOnboardComponent) 
                    // {
                    //     return handleOnboard(values);
                    // }
                }}
            >
                {({ handleSubmit, handleChange, values, errors, setFieldValue, submitForm, submitCount }): React.JSX.Element => 
                {
                    return (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Box className="auth-page-container">
                                {componentConditions.isTokenExpired &&
                                    <Box className="token-expired-container">
                                        <Typography className="token-expired-text">{'Link expired'}</Typography>
                                    </Box>
                                }
                                {
                                    autoAcceptSpinner && <div className="auto-accept-loader-container">
                                        <Spinner animation="border" />
                                        <p>{'Adding you to the organization'}</p>
                                    </div>
                                }
                                {(!componentConditions.isTokenExpired && !autoAcceptSpinner) && <>
                                    <Box className="title-container">
                                        <Typography className="title-content">
                                            {getTitleContent()}
                                        </Typography>
                                        <Typography className="sub-title-content">
                                            {getSubTitleContent()}
                                        </Typography>
                                    </Box>
                                    <Box className="authentication-form-container">
                                        {/* {(isRegisterComponent && componentConditions?.isOnboardUserDetails) || isOnboardComponent ? */}
                                        {(isRegisterComponent) ?
                                            <>
                                                <Form.Group className="userFlow-container-spacing">
                                                    <FormLabelComponent label='First Name' />
                                                    <FormControlComponent 
                                                        type="text"
                                                        value={values.firstName}
                                                        placeholder='Enter your first name'
                                                        onChange={handleChange}
                                                        name={'firstName'}
                                                        required
                                                        isInvalid={!!errors.firstName}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="userFlow-container-spacing">
                                                    <FormLabelComponent label='Last Name' />
                                                    <FormControlComponent 
                                                        type="text"
                                                        value={values.lastName}
                                                        placeholder='Enter your last name'
                                                        onChange={handleChange}
                                                        name={'lastName'}
                                                        required
                                                        isInvalid={!!errors.lastName}
                                                    />
                                                </Form.Group>
                                            </>
                                            : ''}
                                        {/* {(isRegisterComponent && componentConditions?.isOnboardOrgDetails) &&
                                            <>
                                                <Form.Group className="userFlow-container-spacing company-name-form-group">
                                                    <FormLabelComponent label='Company Name' />
                                                    <FormControlComponent 
                                                        type="text"
                                                        value={values.companyName}
                                                        onChange={handleChange}
                                                        name={'companyName'}
                                                        placeholder='Enter your company name'
                                                        required
                                                        isInvalid={submitCount > 2 && !!errors.companyName}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="userFlow-container-spacing subDomain-form-group">
                                                    <FormLabelComponent label='Instance URL' />
                                                    <Box className="subDomain-input-container">
                                                        <FormControlComponent 
                                                            type="text"
                                                            value={values.subdomain}
                                                            onChange={(event):void => 
                                                            {
                                                                setFieldValue('subdomain', event.target.value.toLowerCase());
                                                                setSubDomainName(event.target.value.toLowerCase());
                                                            }}
                                                            name={'subDomain'}
                                                            placeholder='Enter your instance URL'
                                                            required
                                                            isInvalid={submitCount > 2 && !!errors.subdomain}
                                                        />
                                                        <Box className="validation-container">
                                                            {subDomainSpinner ? <Spinner size="sm" className={subDomainSpinner ? 'subdomain-validate-spinner' : 'subdomain-validate-spinner-disable'} /> : ''}
                                                        </Box>
                                                    </Box>
                                                    <p className="instance-url-sample">{`https://${values.subdomain?.length > 1 ? values.subdomain : '*'}.eventhq.com`}</p>
                                                    {values?.subdomain?.length > 1 && !componentConditions.isSubDomainAvailable && <Typography className="error-msg">{'Link not available'}</Typography>}
                                                </Form.Group>
                                            </>
                                        } */}

                                        {/* {isOnboardComponent &&
                                            <Form.Group className="userFlow-container-spacing email-form-group">
                                                <FormLabelComponent label='Email' />
                                                <FormControlComponent 
                                                    disabled
                                                    type="email"
                                                    value={userObj.email}
                                                    onChange={handleChange}
                                                    name={'email'}
                                                    style={{
                                                        backgroundColor: 'var(--Component-colors-Components-Form-Input-bg, #f5f5f5)',
                                                    }}
                                                    required
                                                    isInvalid={!!errors.email}
                                                />
                                            </Form.Group>
                                        } */}

                                        {/* {(isResetPasswordComponent || (isRegisterComponent && componentConditions?.isOnboardUserDetails) || isOnboardComponent) && */}
                                        {(isResetPasswordComponent || (isRegisterComponent && componentConditions?.isOnboardUserDetails)) &&
                                            <Form.Group className="userFlow-container-spacing password-form-group">
                                                <FormLabelComponent label={'Password'} />
                                                <Box className="password-input-container">
                                                    <FormControlComponent
                                                        type={componentConditions.showPassword ? 'text' : 'password'}
                                                        value={values.password}
                                                        onChange={(event):void => 
                                                        {
                                                            setFieldValue('password', event.target.value);
                                                            handlePasswordEligibility(event.target.value);
                                                        }}
                                                        name={'password'}
                                                        required
                                                        isInvalid={!!errors.password}
                                                    />
                                                    <IconButton type="button" className="visibility-toggle-button" aria-label="show-password" onClick={handleTogglePassword}>
                                                        {!componentConditions.showPassword ? <FontAwesomeIcon icon={['fal', 'eye']} className="show-hide-icon" /> : <FontAwesomeIcon icon={['fal', 'eye-slash']} className="show-hide-icon" />}
                                                    </IconButton>
                                                </Box>
                                            </Form.Group>
                                        }

                                        {/* {(!componentConditions?.isOnboardOrgDetails) ? <Box className="userFlow-container-spacing password-eligibility-check-container"> */}
                                        {<Box className="userFlow-container-spacing password-eligibility-check-container">
                                            <Box className="password-eligibility-inner-container">
                                                <FontAwesomeIcon icon={['fal', 'circle-check']} className={componentConditions.passwordHas8Characters ? 'criteria-passed-icon' : 'criteria-not-passed-icon'} />
                                                <Typography className="password-eligibility-text">{'Must be at least 8 characters'}</Typography>
                                            </Box>
                                            <Box className="password-eligibility-inner-container">
                                                <FontAwesomeIcon icon={['fal', 'circle-check']} className={componentConditions.passwordHasSpecialCharacterAndCapitalAndUppercase ? 'criteria-passed-icon' : 'criteria-not-passed-icon'} />
                                                <Typography className="password-eligibility-text">{'Must contain one special character, Capital Letter and a Number'}</Typography>
                                            </Box>
                                        </Box>}

                                        {/* {(!componentConditions?.isOnboardOrgDetails) && <Form.Group className="userFlow-container-spacing confirm-password-form-group"> */}
                                        {<Form.Group className="userFlow-container-spacing confirm-password-form-group">
                                            <FormLabelComponent label='Confirm Password' />
                                            <Box className="password-input-container">
                                                <FormControlComponent
                                                    type={componentConditions.showConfirmPassword ? 'text' : 'password'}
                                                    value={values.confirmPassword}
                                                    onChange={handleChange}
                                                    name={'confirmPassword'}
                                                    required
                                                    isInvalid={!!errors.confirmPassword}
                                                />
                                                <IconButton type="button" className="visibility-toggle-button" aria-label="show-password" onClick={handleToggleConfirmPassword}>
                                                    {!componentConditions.showConfirmPassword ? <FontAwesomeIcon icon={['fal', 'eye']} className="show-hide-icon" /> : <FontAwesomeIcon icon={['fal', 'eye-slash']} className="show-hide-icon" />}
                                                </IconButton>
                                            </Box>
                                        </Form.Group>}

                                        {componentConditions.isErrorFromServer && <Box className="invalid-content-container">
                                            <Typography className="invalid-main-content">{errorMsgFromServer}</Typography>
                                        </Box>}

                                        {/* {(isResetPasswordComponent || isRegisterComponent || isOnboardComponent) && */}
                                        {(isResetPasswordComponent || isRegisterComponent) &&
                                            <CustomButton
                                                btnType='primary'
                                                style={{ width: '100%', fontSize: '16px', height: '44px', maxHeight: '44px', marginTop: '16px'}}
                                                type={componentConditions?.isOnboardUserDetails ? "button" : "submit"}
                                                loading={spinner}
                                                onClick={() => {
                                                    submitForm().then(() => {
                                                        // If the form is valid, submitForm will not throw an error
                                                        const isAllUserDetailsFilled = (values.firstName && values.lastName && values.password && values.confirmPassword) !== '';
                                                        if(isAllUserDetailsFilled && componentConditions?.passwordHas8Characters && componentConditions?.passwordHasSpecialCharacterAndCapitalAndUppercase) {
                                                            setComponentConditions((prevItems) => {
                                                                return {
                                                                    ...prevItems,
                                                                    isOnboardUserDetails: true,
                                                                    // isOnboardOrgDetails: true,
                                                                }; 
                                                            });
                                                        }
                                                    }).catch((error) => {
                                                        console.log(error);
                                                    });
                                                }}
                                                // name={isResetPasswordComponent ? 'Reset Password' : isRegisterComponent || isOnboardComponent ? 'Continue' : ''}
                                                name={isResetPasswordComponent ? 'Reset Password' : isRegisterComponent ? 'Continue' : ''}
                                            />
                                        }

                                        {/* {isRegisterComponent && componentConditions?.isOnboardOrgDetails && 
                                        (<Box className="userDetail-redirect-container">
                                            <CustomButton style={{ color: 'var(--Component-colors-Components-Buttons-Tertiary-button-tertiary-fg, #475467)', fontWeight: 600 }} startIcon={<FontAwesomeIcon icon={['fal', 'arrow-left']} />} btnType='infoBlue' name='Back to Log in' type="button" onClick={() => {
                                                setComponentConditions((prevItems) => 
                                                {
                                                    return {
                                                        ...prevItems,
                                                        isOnboardUserDetails: true,
                                                        isOnboardOrgDetails: false,
                                                    }; 
                                                });
                                            }} />
                                        </Box>)} */}
                                    </Box>
                                </>}
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </Box >
    );
};

export default UserFlowPage;
