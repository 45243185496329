import { createSlice } from '@reduxjs/toolkit';

export const PaginationRowSlice = createSlice({
    name: 'pagination',
    initialState: {
        value: {
            registrants: {
                page: 1,
                rowsPerPage: 100,
            }
        }
    },
    reducers: {
        addPagination: (state, action):void => 
        {
            state.value = {
                ...state.value,
                ...action.payload
            };
        },
        resetPagination: (state):void => 
        {
            state.value = {
                registrants: {
                    page: 1,
                    rowsPerPage: 100,
                }
            };
        },
    },
});

export const { addPagination, resetPagination } = PaginationRowSlice.actions;
export default PaginationRowSlice.reducer;