import { Box, Button, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import APP_CONSTANTS from '../scripts/constants';
import eventBus from '../scripts/event-bus';
import './styles.scss';
import { CustomButton } from './FormComponents/Buttons';

/**
 * @returns
 * Functional component to render a Top App Bar
 * Variable to store the state of top app bar's heading
 * Variable to store the state of the top app bar button name
 * Variable to store the state of the top app bar button to render a component
 * Variable to store the state of the top app bar spinner
 * Variable to store the state of the disabled button
 * Function to handle the top app bar button click
 */

const TopAppBar = (): React.JSX.Element => 
{

    const [topNavHeading, setTopNavHeading] = useState('');
    const [, setListCount] = useState('');
    const [topNavButtonName, setTopNavButtonName] = useState('');
    const [onClickViewComponent, setOnClickViewComponent] = useState('');
    const [spinner, setSpinner] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);
    const [importExportStack, setImportExportStack] = useState(<div></div>);
    const [backBtn, setBackBtn] = useState(false);
    const [closedChartsButton, setClosedChartsButton] = useState(false);
    const [closedChartClickEventName, setClosedChartClickEventName] = useState('');
    const [isChartClosed, setIsChartClosed] = useState(true);
    
    const handleAppBarButton = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.TOP_NAV_BAR.BUTTON_CLICK_EVENT, {
            onClickViewComponent
        });
    };

    useEffect((): void => 
    {
        const fetchData = async (): Promise<void> => 
        {

            eventBus.on(APP_CONSTANTS.EVENTS.TOP_NAV_BAR.UPDATE_NAME_EVENT, (data): void => 
            
            {
                setTopNavHeading(data.heading);
                setListCount(data.listCount);
                setTopNavButtonName(data.buttonName);
                setOnClickViewComponent(data.onClickViewComponent);
                setDisabledButton(data.disabledButton);
                setImportExportStack(data.importExportStack);
                setBackBtn(data.backBtn);
                if(data.closedChartsButton === true)
                {
                    setClosedChartsButton(data.closedChartsButton);
                    setIsChartClosed(true);
                    setClosedChartClickEventName(data.closedChartClickEventName);
                }
                if(data.closedChartsButton === false || data.closedChartsButton === undefined)
                {
                    setClosedChartsButton(false);
                }
               
            });
            eventBus.on(APP_CONSTANTS.EVENTS.TOP_NAV_BAR.HANDLE_DISABLE, (data): void => 
            {
                setDisabledButton(data.disabledButton);
            });
            eventBus.on(APP_CONSTANTS.EVENTS.TOP_NAV_BAR.UPDATE_BUTTON, (data): void => 
            {
                setSpinner(data.spinner);
                setDisabledButton(data.disabledButton);
            });
        };
        fetchData();
    }, []);

    return (
        <Box id="topAppBar">
            {/* <TopBar /> */}
            <Box className={backBtn ? 'tool-bar-back-btn' : 'tool-bar'} width={'100%'} p={2}>
                <Stack className="heading-stack" direction={'row'} spacing={1}>
                    {topNavHeading ? <Typography className="app-bar-heading">{topNavHeading}</Typography> : null}
                    {/* {listCount ? <Typography className="list-count">{listCount}</Typography> : null} */}
                </Stack>
                <Box className="action-btn">
                    {closedChartsButton &&
                    <CustomButton btnType='secondary'
                        onClick={():void => 
                        {
                            eventBus.dispatch(closedChartClickEventName, {
                                closeChart: !isChartClosed
                            });
                            setIsChartClosed(!isChartClosed);
                        }}
                        type='button'
                        name={isChartClosed ? 'Open Charts' : 'Close Charts'}/>}
                    {importExportStack ? importExportStack : null}
                    {topNavButtonName ? <CustomButton name={topNavButtonName} onClick={handleAppBarButton} disabled={disabledButton} loading={spinner} btnType={'primary'} /> : null}
                </Box>
            </Box>
        </Box>
    );
};

export default TopAppBar;