import { CurrentUserOrgInfo, Organization } from '../../interfaces/settings/organization_interface';
import APP_CONSTANTS from '../constants';
import { triggerApi } from '../helpers';

export const orgMe = async (): Promise<Organization> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN  + APP_CONSTANTS.API_V1 + '/organisations/me';
    return triggerApi(API_URL, 'GET', '', null);
};

export const checkOrgLinkAvailability = async (link: string): Promise<string> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN  + APP_CONSTANTS.API_V1 + '/organisations/link/' +link;
    return triggerApi(API_URL, 'GET', '');
};

export const updateOrgLink = async (LINK: string): Promise<Organization> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN  + APP_CONSTANTS.API_V1 + '/organisations/link/';
    return triggerApi(API_URL, 'PUT', LINK, null);
};

export const uploadOrgImage = async (imageData: FormData, csrfToken: string): Promise<string> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/image/upload/org';
    return triggerApi(API_URL, 'POST', imageData, csrfToken, false);
};

// export const deleteOrg = async () =>
// {
//     const API_URL = APP_CONSTANTS.API_DOMAIN  + APP_CONSTANTS.API_V1 + '/organisations';
//     return triggerApi(API_URL, 'DELETE', '', null);
// };

export const updateOrgDetails = async (org): Promise<Organization> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN  + APP_CONSTANTS.API_V1 + '/organisations';
    return triggerApi(API_URL, 'PUT', org, null);
};

export const getUsersOrganizations = async(): Promise<CurrentUserOrgInfo[]> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/organisations/active-org';
    return triggerApi(API_URL, 'GET', '');
}

export const switchOrganization = async(orgId : string, csrfToken: string): Promise<Boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/organisations/active-org/'+ orgId;
    console.log(API_URL);
    return triggerApi(API_URL, 'POST', null, csrfToken);
}

// export const leaveOrg = async () =>
// {
//     const API_URL = APP_CONSTANTS.API_DOMAIN  + APP_CONSTANTS.API_V1 + '/users/leave-organisation';
//     return triggerApi(API_URL, 'DELETE', '', null);
// };
