export const LocalStorage = {
    get(key) 
    {
        return JSON.parse(localStorage.getItem(key));
    },
    set(key, value) 
    {
        // const placeHolder = '{"id":"1","email":"developers@eventhq.io","firstName":"Pragruthaa Varshini","lastName":"Rabichandran","status":1,"link":"scuser1","additionalInfo":{"image":"https://res.cloudinary.com/quicklinks/image/upload/v1669225593/users/1/profile_picture.png"},"orgId":"1","orgStatus":1,"role":3,"created":"2022-06-06T15:10:11.339Z","modified":"2022-11-23T17:46:35.518Z"}';
        // localStorage.setItem(placeHolder);
        localStorage.setItem(key, JSON.stringify(value));
    },
    remove(key) 
    {
        localStorage.removeItem(key);
    },
    removeAll()
    {
        localStorage.clear();
    }
};
