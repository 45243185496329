import { Box } from '@mui/material';
import React from 'react';
import './styles.scss';


interface IFormTabsProps {
    tabName: string | React.JSX.Element;
    count?: number | string;
    selectedTab: boolean;
    navigation: () => void;
}

interface IFormTabs {
    tabs: IFormTabsProps[];

}

const FormTabs = ({ tabs }: IFormTabs): React.JSX.Element => 
{
    return (
        <Box id='formBoxTabs'>
            {tabs.length > 0 && tabs.map((tabs, index):React.JSX.Element => 
            {
                return (
                    <Box className={`${tabs.selectedTab ? 'selected-tab-btn':'tab-btn'}`} key={index} onClick={tabs.navigation}>
                        {tabs.tabName}
                        {tabs?.count ? <span className='count'>{tabs.count}</span> : null}
                    </Box>
                );
            })}

        </Box>
    );
};

export default FormTabs;