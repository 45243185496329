import { createSlice } from '@reduxjs/toolkit';

export const csrfTokenSlice = createSlice ({
    name: 'csrfTokenValue',
    initialState: {
        value: {
            csrfToken: ''
        }
    },
    reducers: {
        csrfTokenValue: (state, action) => 
        {
            state.value = {
                ...state.value,
                ...action.payload
            };
        }
    },
});

export const { csrfTokenValue } = csrfTokenSlice.actions;
export default csrfTokenSlice.reducer;