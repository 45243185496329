import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'users',
    initialState: {
        value: {
            // users:[{
            //     id:0, role: 2, email: ''
            // }]     
            firstName: '',
            lastName: '',
            email: '',
            role: null,
        }
    },
    reducers: {
        addUsers: (state, actions) => {
            state.value = {
                ...state.value,
                ...actions.payload,
            };
        },
        resetUsers: (state) => {
            state.value = {
                firstName: '',
                lastName: '',
                email: '',
                role: null,
            }
        },
        // addUsers: (state) =>
        // {
        //     state.value.users.push({
        //         id:0, role:2, email:''
        //     });
        // },
        // updateUsers: (state, actions) => 
        // {
        //     const user = actions.payload;
        //     const list = [...state.value.users];
        //     list[user.index][user.name] = user.value;
        // },
        // removeUsers: (state, actions) => 
        // {
        //     state.value.users.splice(actions.payload.index,1);
        // }
    },
});

// export const { users } = userSlice.actions;
export const { addUsers, resetUsers } = userSlice.actions;
// export const { updateUsers } = userSlice.actions;
// export const { removeUsers } = userSlice.actions;
export default userSlice.reducer;