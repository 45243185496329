// import ENV from '../config/env.js'

const ENV = import.meta.env;
const APP_CONSTANTS = {
    EMAIL_REGEX: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*)@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
    EMAIL_REGEX_STR: '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*)@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])',
    LINK_REGEX: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
    HAS_UPPERCASE: /[A-Z]/,
    HAS_LOWERCASE: /[a-z]/,
    HAS_SPECIAL_CHARACTER: /^(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]+$/,
    TEXT_SANITIZE_REGEX: /^([a-zA-Z0-9\\@\\!\-_\s]+)$/,
    SANITIZE_HTML_TAGS: /<\/?[^>]+(>|$)/g,
    ALPHA_NUMERIC_HYPHEN_UNDERSCORE: /^[a-zA-Z0-9-_]+$/,
    LOWER_CASE_ALPHA_NUMERIC_HYPHEN_UNDERSCORE: /^[a-z0-9-_]+$/,
    LOWER_CASE_HYPHEN: /^[a-z\-]+$/,
    TEXT_SANITIZE_REGEX_STR: '([a-zA-Z0-9@!\\-_\\s]+)',
    LINK_BLOCK_HREF: 'javascript:void(0)',
    APP_DOMAIN: ENV.VITE_API_SERVER_PROTOCOL + '://' + ENV.VITE_APP_SERVER_HOST,
    API_DOMAIN: ENV.VITE_API_SERVER_PROTOCOL + '://' + ENV.VITE_API_SERVER_HOST,
    APP_MAIN_DOMAIN: ENV.VITE_APP_DOMAIN,
    API_V1: '/api/v1',
    DOMAIN: ENV.VITE_API_SERVER_HOST.replace(/api\./g, ''),
    PROTOCOL: ENV.VITE_API_SERVER_PROTOCOL,
    THREE_SECONDS: 3000,
    CONSUMER_APP_URL: ENV.VITE_API_SERVER_PROTOCOL + '://' + ENV.VITE_CONSUMER_APP_URL,
    DOMAIN_REDIRECT: ENV.VITE_API_SERVER_PROTOCOL + '://' + ENV.VITE_API_SERVER_HOST.replace(/api\./g, ''),
    SERVER_HOST: ENV.VITE_APP_SERVER_HOST,
    MAIN_DOMAIN: ENV.VITE_APP_SERVER_MAIN_DOMAIN,
    ACCEPTED_HOST: [ENV.VITE_APP_SERVER_HOST, ENV.VITE_API_SERVER_HOST],
    CMDK_TOKEN: ENV.VITE_CMDK_TOKEN,
    EVENTS: {
        TRIGGER_SIDE_ACTION_BAR_CHANGES: 'side-action-bar-changes',
        TOP_NAV_BAR: {
            BUTTON_CLICK_EVENT: 'top-navbar-click',
            UPDATE_NAME_EVENT: 'update-topnavbar-name',
            UPDATE_BUTTON: 'update-top-navbar-button',
            HANDLE_DISABLE: 'handle-disable-button',
            MULTI_SELECT_DATA: 'multi-select-data',
            IMPORT_EXPORT_BTN_VISIBILITY: 'import-export-visibility',
        },
        SETTINGS_TOP_NAV_BAR: {
            BUTTON_CLICK_EVENT: 'settings-top-navbar-click',
            UPDATE_NAME_EVENT: 'settinsg-update-topnavbar-name',
            UPDATE_BUTTON: 'settings-update-top-navbar-button',
            HANDLE_DISABLE: 'settings-handle-disable-button',
            MULTI_SELECT_DATA: 'settings-multi-select-data',
        },
        FILTERS: {
            FILTER_ROW: 'filter-row-data',
            FILTERED_ROW: 'filtered-row-data',
            RESET_FILTER: 'filter-reset',
            RESET_FILTER_STRING: 'reset',
        },
        SOFT_RELOAD: 'soft-reload',
        SIDE_NAV_BAR: {
            LOGOUT_COMPLETED: 'logout-completed',
        },
        SIDE_DRAWER: {
            UPDATE_EVENT: 'update-side-drawer',
            OPEN_EVENT: 'open-side-drawer',
            CLOSE_EVENT: 'close-side-drawer',
            HIDE_CLOSE_ICON: 'hide-close-icon',
            REQUIRED_SHOW: 'required-show',
            FORM_SUBMIT_CONFIRMATION: 'form-submitted',
            UPDATE_BUTTON: 'update-side-drawer-button',
            BACK_BUTTON: 'side-drawer-back',
            DISABLE_ACTION: 'disable-action-button',
            ACCESS_TYPES: {
                EDIT_ACCESS: 'edit-access',
                VIEW_ACCESS: 'view-access',
            },
            LINKS: {
                CREATE_EVENT: 'create-link',
                EDIT_EVENT: 'edit-link',
            },
            FAVOURITES: {
                CREATE_EVENT: 'create-link-favourites',
                EDIT_EVENT: 'edit-link-favourites',
            },
            EVENTS: {
                CREATE_EVENT: 'create-event',
                EDIT_EVENT: 'edit-event',
            },
            SPEAKERS: {
                CREATE_EVENT: 'open-speakers-component-create',
            },
            SPONSORS: {
                CREATE_EVENT: 'open-sponsors-component-create',
            },
            ICP: {
                CREATE_EVENT: 'open-icp-component-create',
            },
            BUYER_PERSONA: {
                CREATE_EVENT: 'open-buyer-persona-component-create',
            },
            CUSTOM_FIELD: {
                CREATE_EVENT: 'open-custom-field-component-create',
            },
            VENDORS: {
                CREATE_EVENT: 'open-vendors-component-create',
            },
            REGISTRATIONS: {
                CREATE_EVENT: 'create-registration-form',
            },
            USERS: {
                CREATE_EVENT: 'create-user',
                UPDATE_EVENT: 'update-users-list',
                EDIT_EVENT: 'edit-user',
                UPLOAD_CSV: 'upload-csv',
                EDIT_DATA: 'user-edit-data',
            },
            TEAMS: {
                ADD_MEMBERS: 'add-members',
                OPEN_MEMBERS: 'open-members-component',
                CREATE_EVENT: 'create-team',
                EDIT_EVENT: 'edit-team'
            }
        },
        DIALOG: {
            UPDATE_EVENT: 'update-dialog',
            OPEN_EVENT: 'open-dialog',
            CLOSE_EVENT: 'close-dialog',
        },
        DIALOG_SECONDARY: {
            UPDATE_EVENT: 'update-dialog-secondary',
            OPEN_EVENT: 'open-dialog-secondary',
            CLOSE_EVENT: 'close-dialog-secondary',
        },
        SNACKBAR_DIALOG: {
            UPDATE_EVENT: 'update-snackbar-dialog-secondary',
            OPEN_EVENT: 'open-snackbar-dialog-secondary',
            CLOSE_EVENT: 'close-snackbar-dialog-secondary',
        },
        LINK_PAGE: {
            ON_CLICK_VIEW_COMPONENT_CREATE: 'open-links-component-create',
            CELL_CLICK_HANDLER: 'links-cell-click-handler',
            CRUD_ACTION: {
                UPDATE_LINK: 'update-links-list',
                DELETE_LINK: 'delete-link',
            },
            TABLE_ACTIONS: {
                UPDATE_FAVORITE: 'update-favorite',
                UPDATE_PIN: 'update-pin',
            }
        },
        FAVOURITES_PAGE: {
            ON_CLICK_VIEW_COMPONENT_CREATE: 'open-links-component-create',
            CELL_CLICK_HANDLER: 'links-cell-click-handler',
            CRUD_ACTION: {
                UPDATE_LINK: 'update-favourites-list',
                DELETE_LINK: 'delete-link-favourites-page'
            },
            TABLE_ACTIONS: {
                UPDATE_FAVORITE: 'update-favorite-favourites-page',
                UPDATE_PIN: 'update-pin-favourites-page',
            }
        },
        EVENTS_PAGE: {
            ON_CLICK_VIEW_COMPONENT_CREATE: 'open-events-component-create',
            CRUD_ACTION: {
                UPDATE_LINK: 'update-events-list',
                DELETE: 'delete-event',
            },
            TABLE_ACTIONS: {
                UPDATE_FAVORITE: 'update-favorite',
                UPDATE_PIN: 'update-pin',
            },
        },
        AUDIENCE: {
            DELETE: 'delete-audience'
        },
        SPEAKERS: {
            DELETE: 'delete-speaker',
        },
        SPONSORS: {
            DELETE: 'delete-sponsor',
        },
        VENDORS: {
            DELETE: 'delete-vendor',
        },
        REGISTRATIONS: {
            DELETE: 'delete-form',
        },
        EVENT_TICKETS: {
            TICKET: {
                COUNT_UPDATED: 'ticket-count-updated',
                DELETE: 'delete-ticket'
            },
            TICKET_GROUP: {
                COUNT_UPDATED: 'ticket-group-count-updated',
                DELETE: 'delete-ticket-group'
            }
        },
        EVENT_SESSION: {
            DELETE: 'delete-session',
        },
        EVENT_STAGE: {
            DELETE: 'delete-stage',
        },
        EVENT_EXPO: {
            DELETE: 'delete-booth',
        },
        EVENT_SCHEDULE: {
            DELETE: 'delete-schedule',
        },
        USER_PAGE: {
            ON_CLICK_VIEW_COMPONENT_CREATE: 'open-users-component-create',
            CELL_CLICK_HANDLER: 'users-cell-click-handler',
            POPUP_DELETE_USER: 'delete-user',
        },
        TEAM_PAGE: {
            ON_CLICK_VIEW_COMPONENT_CREATE: 'open-teams-component-create',
            CELL_CLICK_HANDLER: 'teams-cell-click-handler',
            HANDLE_TEAM_MEMBER: 'handle-team-member-components',
            POPUP_DELETE_TEAM: 'popup-delete-team',
            TEAM_MEMBER_EVENT: 'handle-team-member-components',
        },
        GENERAL_PAGE: {
            ON_CLICK_VIEW_COMPONENT_CREATE: 'update-personal-details',
            CRUD_ACTION: {
                UPDATE_GENERAL_DATA: 'update-general-data',
                UPDATE_GENERAL_IMAGE: 'update-general-page-image',
            }
        },
        ICP: {
            DELETE: 'delete-icp',
        },
        BUYER_PERSONA: {
            DELETE: 'delete-buyer-persona',
        },
        CUSTOM_FIELD: {
            DELETE: 'delete-custom-field',
        },
        ORGANIZATION_PAGE: {
            ON_CLICK_VIEW_COMPONENT_CREATE: 'update-organization-details',
            CRUD_ACTION: {
                UPDATE_ORG_DATA: 'update-organization-data',
                UPDATE_ORG_IMAGE: 'update-organization-page-image',
            }
        },
        POPUP_EVENT: {
            RENDER: 'render-pop-up',
            UPDATE: 'update-pop-up',
            RESULT: 'result-pop-up',
            CLOSE_POPUP_EVENT: 'close-pop-up'
        },
        ALERT: {
            OPEN: 'alert-open',
        },
        TAB_CHANGE: 'tab-change',
        TAB_SELECT: 'tabs-select',
        DATA_GRID: {
            // ROW_CLICK : 'handle-row-click',
            EVENTS_ROW_CLICK: 'events-row-click',
            REGISTRATIONS_ROW_CLICK: 'registrations-row-click',
            AUDIENCE_ROW_CLICK: 'audience-row-click',
            SPEAKERS_ROW_CLICK: 'speakers-row-click',
            SPONSORS_ROW_CLICK: 'sponsors-row-click',
            VENDORS_ROW_CLICK: 'vendors-row-click',
            USERS_ROW_CLICK: 'users-row-click',
            USERS_ROW_DATA: 'users-row-data',
            ICP_ROW_CLICK: 'icp-row-click',
            ICP_ROW_DATA: 'icp-row-data',
            BUYER_PERSONA_ROW_CLICK: 'icp-row-click',
            BUYER_PERSONA_ROW_DATA: 'icp-row-data',
            TEAMS_ROW_CLICK: 'teams-row-click',
            TEAMS_ROW_DATA: 'teams-row-data',
            TICKETS_ROW_CLICK: 'tickets-row-click',
            SCHEDULE_ROW_CLICK: 'schedule-row-click',
            SESSION_ROW_CLICK: 'sessions-row-click',
            STAGE_ROW_CLICK: 'stage-row-click',
            EVENT_REGISTRANT_ROW_CLICK: 'event-registrant-row-click',
            CUSTOM_FIELD_ROW_CLICK: 'custom-field-row-click',
            UTM_ROW_CLICK: 'utm-row-click',
            GOALS_ROW_CLICK: 'goals-row-click'
        },
    },
    SHORTCUTS: {
        _IS: 1,
        _IS_NOT: 2
    },

    SWITCH: {
        ON: 'on',
        OFF: 'off'
    },

    FILTER: {
        EVENTS_FILTER: {
            LOCATION_FILTER_NAME: 'locationType',
            STATUS_FILTER_NAME: 'eventStatus',
            LOCATION_FILTER_OBJECT:
            {
                id: 'TYPE',
                name: 'NAME',
            },
            STATUS_FILTER_OBJECT:
            {
                id: 'NAME',
                name: 'NAME',
            }
        }
    },
    LINK_SHORTCUT_ICON: 'https://s2.googleusercontent.com/s2/favicons?sz=48&domain_url=',
    CSV_FILE: [
        {
            email: 'john.doe@gmail.com',
            role: 1
        },
        {
            email: 'peter.griffin@gmail.com',
            role: 2
        },
        {
            email: 'lois.griffin@gmail.com',
            role: 1
        },
    ],
    SIDE_DRAWER_WIDTH: 240,
    DATA_GRID_NON_CLICKABLE_COLUMNS: ['actions', '__check__', 'eventSpeakerActions', 'copyLink', 'viewPin'],

    GRAPH_COLORS: {
        PIE_CHART: {
            DEFAULT: ['#CAC2FF', '#F9C2FF', '#CBF5E5', '#C2EFFF', '#F8C9D2', '#FBDFB1', '#FFDAC2', '#C2D6FF'],
            //DEFAULT: ['#FDE272','#FDA29B','#BDB4FE','#A4BCFD','#84CAFF','#73E2A3'],
            HOVER: ['#A88BEE', '#EE99F7', '#88DDBE', '#86D5F2', '#EC778D', '#F7CE8C', '#F7B080', '#879EFD'],
            //HOVER: ['#FAC515','#F97066','#9B8AFB','#8098F9','#53B1FD','#3CCB7F'],
        },
        BAR_CHART: {
            DEFAULT: ['#CAC2FF', '#F9C2FF', '#CBF5E5', '#C2EFFF', '#F8C9D2', '#FBDFB1', '#FFDAC2', '#C2D6FF'],           
            HOVER: ['#A88BEE', '#EE99F7', '#88DDBE', '#86D5F2', '#EC778D', '#F7CE8C', '#F7B080', '#879EFD']
        },
        SANKEY_CHART: {
            DEFAULT: ['#CAC2FF', '#F9C2FF', '#CBF5E5', '#C2EFFF', '#F8C9D2', '#FBDFB1', '#FFDAC2', '#C2D6FF'],
            HOVER: ['#A88BEE', '#EE99F7', '#88DDBE', '#86D5F2', '#EC778D', '#F7CE8C', '#F7B080', '#879EFD']
        },
        FUNNEL_CHART: {
            DEFAULT: ['#CAC2FF', '#F9C2FF', '#CBF5E5', '#C2EFFF', '#F8C9D2', '#FBDFB1', '#FFDAC2', '#C2D6FF'],
            HOVER: ['#A88BEE', '#EE99F7', '#88DDBE', '#86D5F2', '#EC778D', '#F7CE8C', '#F7B080', '#879EFD']
        },
        LINE_CHART : {
            DEFAULT: ['#1570EF', '#53B1FD', '#175CD3'],
        },
    }
};

export default APP_CONSTANTS;