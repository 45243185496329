import React from 'react';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import './styles.scss';
import { BpCheckedIcon, BpIcon, BpIndeterminateIcon } from './StyledComponents/CustomCheckBox.styled';

/**
 * @returns
 * Functional component to render a custom checkbox in datagrid
 */

export const BpCheckbox = (props: CheckboxProps) : React.JSX.Element => 
{
    return (
        <Checkbox
            sx={{
                '&:hover': 
                { 
                    bgcolor: 'transparent' 
                },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            indeterminateIcon={<BpIndeterminateIcon />}
            inputProps={{
                'aria-label': 'Checkbox demo' 
            }}
            {...props}
        />
    );
};