import { Image as libImage } from 'image-js';

export const getImageData = async (imageUrl:string):Promise<Uint8Array> => 
{
    try 
    {
        const response = await fetch(imageUrl);

        if (!response.ok) 
        {
            throw new Error('Failed to fetch image');
        }

        const arrayBuffer = await response.arrayBuffer();

        return new Uint8Array(arrayBuffer);
    }
    catch (error) 
    {
        console.log('Error fetching image:', error);
        throw error;
    }
};

export const decompressImage = async (imageUrl: string, height: number, width: number):  Promise<string> => 
{
    try 
    {
        const compressedData = await getImageData(imageUrl);

        const image = await libImage.load(compressedData);

        const resizeImage = image.resize({
            width: width, height: height, preserveAspectRatio: true, factor: 1 
        });

        const imageBlob = await resizeImage.toBlob('image/png', 100);

        const imageDataUri = URL.createObjectURL(imageBlob);

        return imageDataUri;
    }
    catch (error) 
    {
        console.log('Error:', error);
    }
};