import { createSlice } from '@reduxjs/toolkit';

export const organizationSlice = createSlice({
    name: 'organization',
    initialState: {
        value: {
            name: '',
            link: '',
            additionalInfo: null,     
        }
    },
    reducers: {
        updateOrgInfo: (state, action) =>
        {
            state.value = {
                ...state.value,
                ...action.payload
            };
        },
    },
});

export const { updateOrgInfo } = organizationSlice.actions;
export default organizationSlice.reducer;