import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import './styles.scss';
import PhotosPage from './EventDetails/PhotoSharing/PhotosPage';
import EventPhotoSharingProvider from '../../contexts/EventPhotoSharing/EventPhotoSharingContext';
import { UserModeration } from './enum';
import { getEventById } from '../../scripts/apis/events';

const EventsInfo = (props): React.JSX.Element =>         
{  
    const [userModeration, setUserModeration] = useState<UserModeration>(UserModeration.DISABLED);
    const [eventTitle, setEventTitle] = useState<string>('');

    const { eventId } = useParams();
    const pageData = props.data;
    const isPhotoSharingComponent = pageData === 'photoSharing';
    useEffect(() => 
    {
        const eventData = async () =>
        {
            if(eventId)
            {
                const eventData = await getEventById(eventId);
                setUserModeration(eventData?.userModeration);
                setEventTitle(eventData?.title || '');
            }
        }
        eventData();
    }, [eventId]);

    return (
        <Box id="eventsInfo">
            <Box className="w-100 content-box" sx={{
                display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'
            }}>
            {isPhotoSharingComponent && <EventPhotoSharingProvider eventId={eventId || ''} ><PhotosPage eventTitle={eventTitle || ''} userModeration={userModeration === UserModeration.ENABLED} /></EventPhotoSharingProvider>}
            </Box>
        </Box>
    );
};
export default EventsInfo;