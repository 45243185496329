import { createSlice } from '@reduxjs/toolkit';

export const generalSlice = createSlice({
    name: 'general',
    initialState: {
        value: {
            firstName: '',
            lastName: '',
            email: '',
            image: '',
            role: '',
            // additionalInfo: null || {
            //     image: null
            // },
        }
    },
    reducers: {
        updateGeneralInfo: (state, action) => {
            state.value = {
                ...state.value,
                ...action.payload
            };
        },
    },
});

export const { updateGeneralInfo } = generalSlice.actions;
export default generalSlice.reducer;