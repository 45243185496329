export enum EventPhotoSharingStatus 
    {
    ACTIVE = 1,
    HIDE = 2
}

export enum EventPhotoSharingFolderStatus 
    {
    ACTIVE = 1,
    INACTIVE = 2,
    DELETED = 3
}

export enum EventPhotoSharingFolderType
    {
    DEFAULT = 1,
    MAIN = 2,
    USER_UPLOAD = 3
}

export enum EventPhotoSharingDefaultFolder
    {
    DEFAULT_FOLDER = 1,
    FOLDER = 2,
    USER_FOLDER = 3
}

export enum EventPhotoSharingTypeFilter
    {
    DEFAULT = 1,
    HIDE = 2,
    USER = 3,
    APPROVED = 4
}

export enum WatermarkPosition
{
    NORTH = 'north',
    NORTH_EAST = 'northeast',
    EAST = 'east',
    SOUTH_EAST = 'southeast',
    SOUTH = 'south',
    SOUTH_WEST = 'southwest',
    WEST = 'west',
    NORTH_WEST = 'northwest',
    CENTER = 'center'
}