import React from 'react';
import DatePicker from './DatePicker';
import TimePicker from './TimePicker';
import { Moment } from 'moment';

import './styles.scss';

interface DateTimePickerProps {
  dateValue: string | number | null | Moment;
  timeValue: string;
  onDateChange: (date: Moment) => void;
  onTimeChange: (time: string) => void;
  minDate?: string | number | null;
  maxDate?: string | number | null;
  disablePast?: boolean;
  disableFuture?: boolean;
  dateFormat?: string;
  showYearDropdown?: boolean;
}

const DateTimePicker: React.FC<DateTimePickerProps> = ({
  dateValue,
  timeValue,
  onDateChange,
  onTimeChange,
  minDate,
  maxDate,
  disablePast = false,
  disableFuture = false,
  showYearDropdown = false,
  dateFormat = 'ddd, MMM D, YYYY',
}) => {
  return (
    <div id="customDateTimePicker">
        <div className="datetime-picker">
            <DatePicker
                value={dateValue}
                onChange={onDateChange}
                valueFormat={dateFormat}
                minDate={minDate}
                maxDate={maxDate}
                disablePast={disablePast}
                disableFuture={disableFuture}
                showYearDropdown={showYearDropdown}
            />
            <TimePicker value={timeValue} onChange={onTimeChange} />
        </div>
    </div>
  );
};

export default DateTimePicker;
