import { BillingDetails } from '../../interfaces/settings/billing-details_interface';
import { OrgBilling } from '../../interfaces/settings/billing_interface';
import APP_CONSTANTS from '../constants';
import { triggerApi } from '../helpers';

export const getCheckoutId = async (data, csrfToken: string) => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/billing/checkout';
    return await triggerApi(API_URL, 'POST', data, csrfToken);
};

export const getPlans = async () => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/billing/plans';
    return await triggerApi(API_URL, 'GET', '');
};

export const getOrgBilling = async () => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/billing/org-billing-details';
    return await triggerApi(API_URL, 'GET', '');
};

// export const getOrgBillingDetails = async () => {
//     const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/billing/org-billing-info';
//     return await triggerApi(API_URL, 'GET', '');
// };

export const getCustomerPortal = async () => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/billing/customer-portal';
    return await triggerApi(API_URL, 'GET', '');
};

export const orgBillingDetails = async (): Promise<OrgBilling | BillingDetails> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/billing/org-billing-info';
    return await triggerApi(API_URL, 'GET', '');
} 
