import './styles.scss';

interface CustomSkeletonProps { 
    width?: number;
    height?: number;
    variant?: 'rect' | 'circle';
    animation?: 'wave' | 'pulse';
}    

const CustomSkeleton: React.FC<CustomSkeletonProps> = (props): React.JSX.Element =>
{

    const { width, height, variant, animation } = props;

    const skeletonStyle = {
        width: width ? `${width}px` : '100%',
        height: height ? `${height}px` : '20px',
        borderRadius: variant === 'circle' ? '50%' : '4px',
    };

    return (
        <div id="customSkeleton">
            <div
                className={`skeleton ${variant} ${animation}`}
                style={skeletonStyle}
            ></div>
        </div>
    );
};

export default CustomSkeleton;