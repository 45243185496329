import { WorkosLoginType } from '../../enums/auth/workos.enum';
import { AcceptInvite } from '../../interfaces/auth/accept-invite_interface';
import { AuthBearer } from '../../interfaces/auth/auth-bearer_interface';
import { EmailObject } from '../../interfaces/auth/email-object_interface';
import { Onboard } from '../../interfaces/auth/onboard_interface';
import { SandboxUser } from '../../interfaces/auth/sandbox-user_interface';
import { SessionUser } from '../../interfaces/auth/session-user_interface';
import { GoogleSignIn, SignInUser, WorkosChangePassword, WorkOSOAuthCallbackParams } from '../../interfaces/auth/workos_interface';
import { User } from '../../interfaces/settings/user_interface';
import APP_CONSTANTS from '../constants';
import { convertJSONToGETParams, triggerApi } from '../helpers';

export const acceptInvite = async (ONBOARDING) => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/authentication/accept-invite';
    return triggerApi(API_URL, 'PUT', ONBOARDING, null, null, true);
};

export const onboardUser = async (ONBOARDING) => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/authentication/onboard';
    return triggerApi(API_URL, 'PUT', ONBOARDING, null, null, true);
};

export const inviteStatus = async (TOKEN) => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/authentication/invite-status/' + TOKEN;
    return triggerApi(API_URL, 'GET', '', null, null, true);
};

export const login = async (LOGIN_OBJECT) => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/authentication/login';
    return triggerApi(API_URL, 'POST', LOGIN_OBJECT, null, null, true);
};

export const googleAuthenticate = async (ID_TOKEN) => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/authentication/google-authentication';
    return triggerApi(API_URL, 'POST', ID_TOKEN, null, null, true);
};

export const logout = async () => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/authentication/logout';
     return await triggerApi(API_URL, 'POST', '', null, null, true);
};

export const getUserOrgInfoByToken = async (TOKEN: string): Promise<User> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/authentication/token-info/' + TOKEN;
    return await triggerApi(API_URL, 'GET', '', null, null, true);
};

export const resetPassword = async (DATA) => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/authentication/password-reset';
    return await triggerApi(API_URL, 'POST', DATA, null, null, true);
}

export const updatePassword = async (DATA) => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/authentication/forgot-password';
    return await triggerApi(API_URL, 'PUT', DATA, null, null, true);
}

// workos API's

// GET API's

export const integrateWorkOS = async (type: WorkosLoginType) => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/authentication/auth/url/' + type;
    return await triggerApi(API_URL, 'GET', '', undefined, undefined, true);
};

export const getWorkOSCallback = async (code: string, state: WorkOSOAuthCallbackParams, callback?: string) => 
{
    const query = convertJSONToGETParams({
        code,
        state,
        callback
    });

    const API_URL = APP_CONSTANTS.API_DOMAIN + '/authentication/callback?query=' + query;
    return await triggerApi(API_URL, 'GET', '', undefined, undefined, true);
}

// POST API's

export const googleAuthenticateWithWorkOS = async (data: GoogleSignIn): Promise<SessionUser> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/authentication/google/login';
    return await triggerApi(API_URL, 'POST', data, undefined, undefined, true);
}

export const loginWithWorkOS = async (data: SignInUser): Promise<SessionUser>  =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/authentication/login';
    return await triggerApi(API_URL, 'POST', data, undefined, undefined, true);
}

export const logoutWithWorkOS = async (): Promise<boolean> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/authentication/logout';
    return await triggerApi(API_URL, 'POST', '', undefined, undefined, true);
}

export const resetPasswordWithWorkOS = async (data: EmailObject): Promise<boolean | AuthBearer> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/authentication/password-reset';
    return await triggerApi(API_URL, 'POST', data, undefined, undefined, true);
}

export const registerUserWithWorkOS = async (data: SandboxUser): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/authentication/signup';
    return await triggerApi(API_URL, 'POST', data, undefined, undefined, true);
}

// PUT API's

export const onBoardWithWorkOS = async (data: Onboard): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/authentication/onboard';
    return await triggerApi(API_URL, 'PUT', data, undefined, undefined, true);
}

export const acceptInviteWithWorkOS = async (data: AcceptInvite): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/authentication/accept-invite';
    return await triggerApi(API_URL, 'PUT', data, undefined, undefined, true);
}

export const updatePasswordWithWorkOS = async (data: WorkosChangePassword): Promise<Boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/authentication/forgot-password';
    return await triggerApi(API_URL, 'PUT', data, undefined, undefined, true);
}

// DELETE API's

export const deleteUserFromOrgWithWorkOS = async (key: string): Promise<Boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/authentication/delete-orgs-users/' + key;
    return await triggerApi(API_URL, 'DELETE', '', undefined, undefined, true);
}