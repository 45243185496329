import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, Button } from '@mui/material';
import React from 'react';
import { Modal } from 'react-bootstrap';

const DeletePopup: React.FC<{ modalTitle: string; modalContent: string | React.JSX.Element; acceptClick: () => void, acceptBtn: string, rejectBtn: string, cancelClick: () => void, show: boolean, extraActions?: React.JSX.Element[] }> = (props): React.JSX.Element  => 
{
    const { modalTitle, modalContent, acceptClick, acceptBtn, rejectBtn, cancelClick, show, extraActions } = props;

    return (
        <>
            <Modal show={show} onHide={cancelClick} centered>
                <Modal.Header>
                    <Modal.Title>
                        <Typography className="title">{modalTitle}</Typography>
                    </Modal.Title>
                    <FontAwesomeIcon icon={['fal', 'xmark']} className="close-btn" onClick={cancelClick} />
                </Modal.Header>
                <Modal.Body>
                    {typeof(modalContent) === 'string' ? <Typography className="content">{modalContent}</Typography> : modalContent}
                </Modal.Body>
                {acceptBtn || rejectBtn || extraActions ?
                    <Modal.Footer>
                        {rejectBtn ? <Button size="large" className="link-btn" style={acceptBtn? null : {
                            marginRight: '0px'
                        }} onClick={cancelClick}>{rejectBtn}</Button> : ''}
                        {acceptBtn ? <Button className='btn-delete' onClick={acceptClick}>{acceptBtn}</Button> : ''}
                        {
                            extraActions ? extraActions : ''
                        }
                    </Modal.Footer> : ''}
            </Modal>
        </>
    );
};
export default DeletePopup;
