import { Chip } from '@mui/material';
import React from 'react';
import { IMuiChipProps } from './IReusableFormComponents';

const MuiChip = (props: IMuiChipProps): React.JSX.Element => 
{  
    const { label, chipColor } = props;

    const chipClasses = {
        blue: 'chip-blue',
        green: 'chip-green',
        grey: 'chip-grey',
        yellow: 'chip-yellow',
        red: 'chip-red',
        pink: 'chip-pink',
        violet: 'chip-violet',
        null: 'chip-null',
        orange: 'chip-orange'
    };

    return (
        <div id="muiChipComponent">
            <Chip label={label} className={`chip-component ${chipClasses[chipColor]}`} />
        </div>
    );
};

export default MuiChip;