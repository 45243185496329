import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spinner } from "react-bootstrap";
import fileDownload from 'js-file-download'

export const DownloadButton: React.FC<{ downloadableUrl: string, fileName: string }> = ({ downloadableUrl, fileName }) => {
    const [isDownloading, setIsDownloading] = useState<boolean>(false);

    const handleDownload = () => {
        setIsDownloading(true);
        fetch(downloadableUrl)
          .then(response => response.blob())
          .then(blob => {
            fileDownload(blob, fileName);
            setIsDownloading(false);
          })
          .catch(error => {
            console.error('Error downloading file:', error);
            setIsDownloading(false);
          });
    };

    return (
        <div>
            <button className="download-button" onClick={handleDownload} type="button" disabled={isDownloading}>
                <FontAwesomeIcon icon={['fal', 'arrow-down-to-bracket']} />
                {isDownloading ? (
                    <Spinner animation="border" role="status" size="sm">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                ) : (
                    'Download'
                )}
            </button>
        </div>
    );
};
