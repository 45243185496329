import { TimeZone, getTimeZones } from '@vvo/tzdb';

interface IFormattedTimezone {
    name: string;
    value: string;
}

const timeZones = getTimeZones();
const timezoneOptions = [];
const timezoneListArr = () : void => 
{
    timeZones.map((item: TimeZone): void => 
    {
        const utcSplitted = item.currentTimeFormat.split(' ')[0];
        const mainCities = item.mainCities.map((city: string) => city).join(', ');
        timezoneOptions.push({
            name: `GMT${utcSplitted} ${item.countryName} (${mainCities})`,
            value: item.name,
        });
    });
};
timezoneListArr();
const uniqueTimezoneArr = timezoneOptions && timezoneOptions.length > 0 && timezoneOptions.filter((obj, index) => 
{
    return timezoneOptions.findIndex((item): boolean => 
    {
        return item.name === obj.name; 
    }) === index; 
});

const getFormattedCurrentTimezone = (): IFormattedTimezone | null => {
    const currentTimezoneIdentifier = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const currentTimezone = timeZones.find((tz) => tz.group.includes(currentTimezoneIdentifier));

    if (currentTimezone) {
        const utcSplitted = currentTimezone.rawFormat.split(' ')[0];
        const mainCities = currentTimezone?.mainCities.map((city: string) => city).join(', ');
        const formattedTimezone = `GMT${utcSplitted} ${currentTimezone?.countryName} (${mainCities})`;
        return {
            name: formattedTimezone,
            value: currentTimezone.name
        };
    }
    return null;
}; 

export { getFormattedCurrentTimezone, uniqueTimezoneArr };