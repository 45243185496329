import { createSlice } from '@reduxjs/toolkit';

export const billingDetailsSlice = createSlice ({
    name: 'billingInfo',
    initialState: {
        value: {
            billingDetails: null
        }
    },
    reducers: {
        billingDetails: (state, action) => 
        {
            state.value = {
                ...state.value,
                ...action.payload
            };
        }
    },
});

export const { billingDetails } = billingDetailsSlice.actions;
export default billingDetailsSlice.reducer;