import { createContext, useContext, useEffect, useState } from 'react';
import React from 'react';
import { deleteEvent, eventStatusCount, eventsCount, getEvents } from '../scripts/apis/events';
import { useDispatch } from 'react-redux';
import { addEventData, refreshEventsData } from '../redux/events/EventsData';
import { useTablePagination } from './TablePaginationContext';
import _ from 'lodash';
import { Event, EventGraphObject } from '../pages/Events/interfaces';
import toast from 'react-hot-toast';
import { UserContext } from './UserContext';
import { UserViewType } from '../pages/Settings/enum/users.enum';
import { getEncodedParams } from '../scripts/helpers';

interface IEventsPageContext {
    isEmpty: boolean;
    setIsEmpty: (isEmpty: boolean) => void;
    setEventDataCount: (count: number) => void;
    setRows: (rows: any) => void;
    setEvents: (events: any) => void;
    rows: any;
    eventDataCount: number;
    eventTotalDataCount: number,
    events: any;
    deleteEventFromEventsTable: (eventId: string | number) => void;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    pageSize: number;
    updateCurrentPage: (page: number) => void;
    updatePageSize: (size: number) => void;
    showSpinner: boolean;
    statusCounts: EventGraphObject | undefined;
    currentPage: number;
    showDeletePopup: boolean;
    setShowDeletePopup: React.Dispatch<React.SetStateAction<boolean>>;
    currentUrl: string;
    setCurrentUrl: React.Dispatch<React.SetStateAction<string>>;
}

export const eventLocationOptions = [
    {
        name: 'In Person', value: 1, id: 1,
    },
    {
        name: 'Virtual', value: 2, id: 2,
    },
    {
        name: 'Hybrid', value: 3, id: 3,
    },
];

export const eventTypeOptions = [
    {
        name: 'Host', value: 1, id: 1,
    },
    {
        name: 'Sponsored', value: 3, id: 3,
    },
    {
        name: 'Speaker', value: 4, id: 4,
    },
    {
        name: 'Attendee', value: 5, id: 5,
    }
];

export const EventsPageContext = createContext<IEventsPageContext>({
    isEmpty: false,
    setIsEmpty: () => {},
    setEventDataCount: () => {},
    setRows: () => {},
    setEvents: () => {},
    rows: [],
    eventDataCount: 0,
    eventTotalDataCount: 0,
    events: [],
    deleteEventFromEventsTable: () => {},
    setRefresh: () => {},
    pageSize: 0,
    updateCurrentPage: () => {},
    updatePageSize: () => {},
    showSpinner: false,
    statusCounts: undefined,
    currentPage: 0,
    showDeletePopup: false,
    setShowDeletePopup: () => {},
    currentUrl: '',
    setCurrentUrl: () => {},
});

const EventsPageProvider: React.FC<{children: React.ReactNode}> = ({ children }): React.JSX.Element =>
{

    const {
        userDetails
    } = useContext(UserContext);

    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [events, setEvents] = useState<Event[]>([]);
    const [eventDataCount, setEventDataCount] = useState<number>(0);
    const [eventTotalDataCount, setEventTotalDataCount] = useState<number>(0);
    const [rows, setRows] = useState<Event[]>([]);
    const [refresh, setRefresh] = useState<boolean>(false);
    const dispatch = useDispatch();
    const [showSpinner, setShowSpinner] = useState<boolean>(true);
    const [statusCounts, setEventStatusCount] = useState<EventGraphObject>();
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [currentUrl, setCurrentUrl] = useState(window.location.href);

    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

    const fetchCounts = async (): Promise<void> => {
        try 
        {
            const encodedParams = getEncodedParams(currentUrl);
            const count = await eventsCount(encodedParams);
            const statusCount = await eventStatusCount();
            if(statusCount)
            {
                setEventStatusCount(statusCount);
            }
            if (count) 
            {
                setEventDataCount(count);
                setIsEmpty(count == 0 && encodedParams.toString().length === 0);
            }
            if (!eventTotalDataCount)
            {
                setEventTotalDataCount(count);
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
    }
    
    const fetchData = async (): Promise<void> => 
    {
        setShowSpinner(true);
        try 
        {
            const encodedParams = getEncodedParams(currentUrl);
            if (userDetails?.viewType === UserViewType.MINIMAL && eventDataCount)
            {
                const eventsData = await getEvents(eventDataCount, 0, ...encodedParams);
                if (eventsData) 
                { 
                    setEvents([...eventsData]);
                    dispatch(refreshEventsData([]));
                    dispatch(addEventData(eventsData));
                    setRows([...eventsData]);
                    setShowSpinner(false);
                    setRefresh(false);
                }
            }
            else
            {
                const eventsData = await getEvents(pageSize, currentPage - 1, ...encodedParams);
                if (eventsData) 
                {
                    setEvents([...eventsData]);
                    dispatch(refreshEventsData([]));
                    dispatch(addEventData(eventsData));
                    setRows([...eventsData]);
                    setShowSpinner(false);
                    setRefresh(false);
                }
            }

        }
        catch (error) 
        {
            console.log(error);
        }
        finally
        {
            setShowSpinner(false);
        }
    };

    useEffect((): void => 
    {
        if ((currentPage && pageSize > 0) || userDetails?.viewType === UserViewType.MINIMAL) 
        {
            fetchData();
        }
    }, [currentPage, pageSize, userDetails, eventDataCount]);

    useEffect((): void => 
    {
        if (refresh) 
        {
            fetchData();
            fetchCounts();
        }
    }, [refresh]);

    useEffect((): void => 
    {
        fetchCounts();
    }, [currentUrl])

    const deleteEventFromEventsTable = async (eventId: string | number): Promise<void> => 
    {
        try 
            {
                const eventDeleted = await deleteEvent(eventId);
                if (eventDeleted) 
                {
                    setShowDeletePopup(false);
                    setRefresh(true);
                    toast.success('Event deleted successfully');
                }
            }
            catch (error) 
            {
                console.log(error);
            }
    };

    return (
        <EventsPageContext.Provider 
            value={{
                isEmpty, 
                setIsEmpty, 
                setEventDataCount, 
                setRows, 
                setEvents, 
                rows, 
                eventDataCount, 
                eventTotalDataCount,
                events, 
                deleteEventFromEventsTable, 
                setRefresh, 
                pageSize, 
                updateCurrentPage, 
                updatePageSize, 
                showSpinner, 
                statusCounts, 
                currentPage, 
                showDeletePopup,
                setShowDeletePopup,
                currentUrl,
                setCurrentUrl,
            }}
        >
            {children}
        </EventsPageContext.Provider>
    );
};

export default EventsPageProvider;