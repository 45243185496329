import { createSlice } from '@reduxjs/toolkit';

const eventsDataStore = createSlice({
    name: 'eventsData',
    initialState: {
        value: [], 
    },
    reducers: {
        addEventData: (state, action) => {
            state.value.push(...action.payload);
        },
        refreshEventsData:(state,action)=>{
            state.value = [];
        }
    },
});

export const { addEventData, refreshEventsData } = eventsDataStore.actions;
export default eventsDataStore.reducer;
