import { Event, EventGraphObject } from '../../pages/Events/interfaces';
import APP_CONSTANTS from '../constants';
import { convertJSONToGETParams, triggerApi } from '../helpers';

// GET Methods
export const eventsCount = async (queryParams: [string, string][] = []): Promise<number> => {
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams(filteredParams);
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/count'+ '?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const eventStatusCount = async (): Promise<EventGraphObject> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/status-count';
    return triggerApi(API_URL, 'GET', '');
};

export const getEvents = async (pageSize: number, offset: number, ...queryParams: [string, any][]): Promise<Event[]> => {
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams(filteredParams);
    const API_URL = `${APP_CONSTANTS.API_DOMAIN}${APP_CONSTANTS.API_V1}/events?pageSize=${pageSize}&offset=${offset}&${query}`;
    return triggerApi(API_URL, 'GET', '');
};

export const getEventById = async (eventId: string | number): Promise<Event> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/' + eventId;
    return triggerApi(API_URL, 'GET', '');
};

export const searchEvents = async (title: string): Promise<Event[]> => 
{

    const query = convertJSONToGETParams({ 
        title, 
    });

    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/search?' + query;
    return triggerApi(API_URL, 'GET', '');
};

// POST Methods
export const createEvent = async (eventData: Event, csrfToken: string): Promise<Event> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events';
    return triggerApi(API_URL, 'POST', eventData, csrfToken);
};

export const uploadImage = async (eventId: string, imageName: string, imageData: FormData, csrfToken: string): Promise<string> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/' + eventId + '/upload-image/' + imageName;
    return triggerApi(API_URL, 'POST', imageData, csrfToken, false);
};

// PUT Methods
export const updateEvent = async (eventId: string, eventData: Event): Promise<Event> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/' + eventId;
    return triggerApi(API_URL, 'PUT', eventData);
};

// DELETE Methods
export const deleteEvent = async (eventId: number | string): Promise<boolean> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/' + eventId;
    return triggerApi(API_URL, 'DELETE', '');
};
